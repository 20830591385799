import React from "react";
import "../styles/reviews.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import useScrollAnimation from "../custom-hook/scrolleranimation";

const ReviewCard = ({ imgSrc, name, location, bgColor, reviewAr, reviewEn }) => (
  <div className="myCarousel" style={{ backgroundColor: bgColor }}>
    <img
      src={imgSrc}
      alt={`${name} - Client of Micro IT Industry`}
      className="review-image"
    />
    <h3>{name}</h3>
    <h6>{location}</h6>
    {reviewAr && (
      <p className="reviewP" dir="rtl" lang="ar">
        {reviewAr}
      </p>
    )}
    <p className="reviewP">{reviewEn}</p>
  </div>
);

export default function Reviews() {
  const containerRef = useScrollAnimation();

  const reviews = [
    {
      imgSrc: `${process.env.PUBLIC_URL}/assets/clients/tawfiq.png`,
      name: "Tawfiq Aljohani",
      location: "🇸🇦 Saudi Arabia",
      bgColor: "#0066DC",
      reviewAr:
        "إذا كنت تتطلع إلى العمل مع كيان يمكنه مساعدتك في تحقيق إمكاناتك الكاملة، فهذا هو المكان المناسب. لقد لعبوا دورًا رئيسيًا في مساعدتي لتطوير منصة blockchain في فترة زمنية قصيرة وبجودة عالية. وإنني أتطلع إلى العمل معهم في مشاريعي الأخرى.",
      reviewEn:
        "If you are looking to work with an entity that can help you realize your full potential, this is the place. They played a major role in helping me to develop the blockchain platform in a short period of time and with great quality. I look forward to working with them on my other projects.",
    },
    {
      imgSrc: `${process.env.PUBLIC_URL}/assets/clients/cejay.webp`,
      name: "Cejay Riches",
      location: "🇺🇸 United States",
      bgColor: "#0066DC",
      reviewEn:
        "This guy goes above and beyond. He's hands-on and truly has patience like no other. Truly grateful for this guy.",
    },
    {
      imgSrc: `${process.env.PUBLIC_URL}/assets/clients/salwa.png`,
      name: "Salwa Alhajjaji",
      location: "🇸🇦 Saudi Arabia",
      bgColor: "#0066DC",
      reviewAr:
        "لقد سبق لي التعامل مع المهندس محمد في تطوير موقع إلكتروني، وكان تعاونه واستجابته ممتازة. تم إنجاز الموقع في وقت قصير بأفضل طريقة ممكنة",
      reviewEn:
        "I have previously dealt with Engineer Mohammed in developing a website, and his cooperation and response were excellent. The site was completed in a short time and in the best possible way.",
    },
  ];

  return (
    <div className="reviews">
      <div className="line"></div>
      <div className="semi-line"></div>
      <div className="container" ref={containerRef}>
        <div className="reviews_heading">
          <div className="borderlinemid"></div>
          <h1 className="mt-3 d-flex justify-content-center"> Why Customer Love</h1>
              <h1 className="d-flex justify-content-center" style={{ fontWeight: "700" }}>
              Working With Us
              </h1>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000 }}
          loop
          spaceBetween={30}
          // slidesPerView={3}
          breakpoints={{
            // Adjust settings for screens smaller than 768px
            768: {
              slidesPerView: 1, // Show 1 slide at a time on mobile
            },
            1024: {
              slidesPerView: 3, // Show 2 slides at a time on tablets
            },
          }}
        >
          {reviews.map((review, index) => (
            <SwiperSlide key={index}>
              <ReviewCard {...review} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
